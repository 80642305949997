import { BriefcaseIcon, CloudIcon, DeviceTabletIcon, UserGroupIcon } from "@heroicons/react/24/outline";

const features = [
  {
    name: "Gemeinsam Prüfen",
    description:
      "Dank Einladungsfunktion können beliebig viele Nutzer in Ihrem Betrieb die gleichen Leitern verwalten und prüfen.",
    icon: UserGroupIcon,
  },
  {
    name: "Leitern & Tritte verwalten",
    description: "Erstellen Sie neue oder bearbeiten Sie bestehende Leitern – Sie behalten jederzeit den Überblick.",
    icon: BriefcaseIcon,
  },
  {
    name: "Mobil & Desktop",
    description:
      "Prüfen Sie schnell und unkompliziert mit dem Smartphone. Wechseln Sie jederzeit auf Tablet oder Desktop für eine bessere Übersicht.",
    icon: DeviceTabletIcon,
  },
  {
    name: "Jederzeit online verfügbar",
    description: "Ihre Daten sind sicher online gespeichert.",
    icon: CloudIcon,
  },
];

export default function FeatureList() {
  return (
    <div className="bg-white py-24 sm:py-32" id="feature-section">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-primary-700">Schluss mit Papier-Chaos</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Digitalisieren Sie Ihre Leiter- & Trittprüfung
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Mit BGHW Leiter-Check können Sie Ihre Leitern und Tritte digital prüfen und verwalten.
            <br />
            So sparen Sie Zeit und Geld und erhöhen die Sicherheit in Ihrem Unternehmen.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary-700">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

const navigation = {
  main: [
    { name: "Impressum", href: "/imprint" },
    { name: "Datenschutz", href: "/privacy-policy" },
    { name: "Leiter-Training", href: "https://training.leiter-check.bghw.de/" },
    { name: "Kontakt", href: "https://www.bghw.de/online-services-der-bghw/kontaktformular" },
  ],
};

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white">
      <div className="-mt-6 w-12 border-t-2 border-primary-800 mx-auto" />
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
        <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="pb-6">
              <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <p className="mt-6 text-center text-xs leading-5 text-gray-500">
          &copy; {currentYear} Berufsgenossenschaft Handel und Warenlogistik. Alle Rechte vorbehalten.
        </p>
      </div>
    </footer>
  );
}

import { type MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { useOptionalUser } from "~/utils.ts";

import logoShort from "~/assets/logo_short.svg";
import homeScreenshot from "~/../public/screenshots/demo_home.jpg";
import UserMenu from "~/components/user-menu";
import Faq from "./faq";
import FeatureList from "./features";
import Footer from "./footer";

export const meta: MetaFunction = () => [
  {
    title: "BGHW Leiter-Check",
  },
  {
    description:
      "Prüfen Sie schnell und kostenlos Ihre Leitern & Tritte im Unternehmen regelmäßig auf Mängel – mit dem neuen digitalen Angebot der BGHW.",
  },
];

const NavLink = ({
  href,
  children,
  ...props
}: { href: string; children: React.ReactNode } & React.ComponentProps<"a">) => {
  return (
    <a
      href={href}
      className="text-sm block p-2 px-4 rounded-xl border border-transparent hover:border-slate-200 hover:bg-slate-200/30 hover:backdrop-blur-lg font-semibold leading-6 text-gray-900"
      {...props}
    >
      {children}
    </a>
  );
};

export default function Index() {
  const user = useOptionalUser();

  const scrollToFeatureSection = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const element = document.getElementById("feature-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const scrollToFAQSection = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const element = document.getElementById("faq-section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="bg-white">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex max-w-7xl mx-auto items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <div className="flex items-start gap-4">
              <div className="pr-4 border-r-2 border-primary-900">
                <img src={logoShort} alt="BGHW" className="w-20 md:w-32 mb-2" width="350" height="80" />
              </div>
              <h1 className="text-lg md:text-2xl font-semibold -mt-0.5 md:mt-1 ">Leiter&#8209;Check</h1>
            </div>
          </div>
          <div className="hidden xl:flex items-center lg:gap-x-4">
            <NavLink href="#feature-section" onClick={scrollToFeatureSection}>
              Features
            </NavLink>
            <NavLink href="#faq-section" onClick={scrollToFAQSection}>
              FAQ
            </NavLink>
            <div className="[contain:content] w-1 h-1 rounded-full mx-2 bg-slate-900/80" />
            <NavLink href="https://www.bghw.de/online-services-der-bghw/kontaktformular">Kontakt</NavLink>
            <NavLink href="https://www.bghw.de/online-services-der-bghw/">Mehr von BGHW</NavLink>
          </div>
          {user ? (
            <div className="flex gap-2 flex-1 justify-end">
              <Link
                to="/inspect"
                className="ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-primary-800 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-70
          0 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 "
              >
                Zur App
              </Link>
              <div className="hidden md:block">
                <UserMenu small email={user.email} />
              </div>
            </div>
          ) : (
            <div className="items-center flex flex-1 justify-end">
              <Link to="/login" className="text-sm font-semibold leading-6 text-gray-900" unstable_viewTransition>
                Einloggen
              </Link>
              <Link
                to="/register"
                className="ml-8 hidden md:block items-center justify-center rounded-md border border-transparent bg-primary-800 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-70
          0 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 "
                unstable_viewTransition
              >
                Registrieren
              </Link>
            </div>
          )}
        </nav>
      </header>

      <div className="relative isolate pt-14">
        <svg
          className="absolute animate-pulse [animation-duration:12s] inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern id="f4215720-9ba9" width={200} height={200} x="50%" y={-1} patternUnits="userSpaceOnUse">
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-primary-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#f4215720-9ba9)" />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <div className="flex">
              {/* <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                <span className="font-semibold text-primary-600">Neue Features</span>
                <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                <a href="#" className="flex items-center gap-x-1">
                  <span className="absolute inset-0" aria-hidden="true" />
                  Änderungen anzeigen
                  <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </a>
              </div> */}
            </div>
            <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Leitern <b className="text-primary-900">digital</b> prüfen
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Prüfen Sie schnell und kostenlos Ihre Leitern & Tritte im Unternehmen regelmäßig auf Mängel – mit dem
              neuen digitalen Angebot der BGHW.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <Link
                to="/register"
                className="rounded-md bg-primary-900 px-5 py-3 text-sm font-semibold text-white shadow-lg hover:bg-primary-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-700"
                unstable_viewTransition
              >
                Jetzt starten
              </Link>
              <a
                href="#feature-section"
                onClick={scrollToFeatureSection}
                className="text-sm rounded-md py-2.5 px-5 font-semibold leading-6 text-gray-900 border border-slate-100 hover:border-slate-200 hover:bg-slate-100"
              >
                Mehr erfahren <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <svg viewBox="0 0 366 729" role="img" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img src={homeScreenshot} alt="" />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
      <FeatureList />
      <Faq />
      <Footer />
    </div>
  );
}
